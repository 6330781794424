import { HStack } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';

import { ContactInfo, SocialMediaLink } from '~/components/contact-info';
import { StandardLayout } from '~/components/layout';
import { Section } from '~/components/section';

const ContactPage = ({ data }: PageProps<Queries.ContactPageQuery>) => {
  const person = data.contentfulPerson;

  return (
    <StandardLayout>
      <Section title="Contact me">
        <ContactInfo person={person} />
        <HStack justifyContent="flex-end" marginTop={32}>
          {person?.socials?.map(
            (link) =>
              link?.name &&
              link.url && <SocialMediaLink title={link.name} url={link.url} />
          )}
        </HStack>
      </Section>
    </StandardLayout>
  );
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPage {
    contentfulPerson(isPortfolioOwner: { eq: true }) {
      email
      phone
      address
      socials {
        name
        url
      }
    }
  }
`;
