import { Heading } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export interface SectionProps {
  title?: string;
  isRightAligned?: boolean;
}

export const Section = ({
  title,
  isRightAligned = false,
  children,
}: PropsWithChildren<SectionProps>) => (
  <section>
    {title && (
      <Heading
        fontWeight="200"
        textTransform="uppercase"
        marginBottom="10"
        textAlign={isRightAligned ? 'right' : 'left'}>
        {title}
      </Heading>
    )}
    {children}
  </section>
);
