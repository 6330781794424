import { useStaticQuery, graphql } from 'gatsby';

interface QueryResult {
  site: Queries.Site;
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          redirectLink404
          navigationLinks {
            label
            url
          }
        }
      }
    }
  `);

  return site.siteMetadata;
};
