import { HStack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

export const Footer = () => (
  <HStack>
    <StaticImage
      src="./footer-logo.png"
      alt="Roel Vanstiphout footer logo"
      width={80}
      layout="fixed"
      placeholder="blurred"
    />
  </HStack>
);
