import { Link, List, ListIcon, ListItem } from '@chakra-ui/react';

import { Address, Email, IconComponent, Phone } from '~/components/icons';

export interface ContactInfoProps {
  person: Queries.Maybe<
    Pick<Queries.ContentfulPerson, 'email' | 'phone' | 'address'>
  >;
}

interface ContactInfoIconProps {
  icon: IconComponent;
}

const ContactInfoIcon = ({ icon }: ContactInfoIconProps) => (
  <ListIcon as={icon} marginInlineEnd="4" />
);

export const ContactInfo = ({ person }: ContactInfoProps) => (
  <List spacing={3}>
    {person?.email && (
      <ListItem>
        <ContactInfoIcon icon={Email} />
        <Link href={`mailto:${person?.email}`}>{person?.email}</Link>
      </ListItem>
    )}
    {person?.phone && (
      <ListItem>
        <ContactInfoIcon icon={Phone} />
        <Link href={`tel:${person?.phone}`}>{person?.phone}</Link>
      </ListItem>
    )}
    {person?.address && (
      <ListItem>
        <ContactInfoIcon icon={Address} />
        <Link
          href={`https://www.google.com/maps/search/?api=1&query=${person?.address}`}
          target="_blank">
          {person?.address}
        </Link>
      </ListItem>
    )}
  </List>
);
