import { HStack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import { Navigation, NavigationItem } from '~/components/navigation';
import { useSiteMetadata } from '~/hooks/content';

export const Header = () => {
  const siteMetadata = useSiteMetadata();

  return (
    <HStack py={[4, null, 8]} justifyContent="space-between">
      <Navigation>
        {siteMetadata?.navigationLinks?.map((navigationLink) =>
          !(navigationLink?.label && navigationLink?.url) ? (
            <></>
          ) : (
            <NavigationItem
              key={navigationLink.url}
              label={navigationLink.label}
              to={navigationLink.url}
            />
          )
        )}
      </Navigation>
      <StaticImage
        src="./header-logo.png"
        alt="Roel Vanstiphout header logo"
        width={48}
        layout="fixed"
        placeholder="blurred"
      />
    </HStack>
  );
};
