import { useMemo } from 'react';
import { LinkBox, LinkOverlay } from '@chakra-ui/react';

import {
  IconComponent,
  GenericSocial,
  Facebook,
  Instagram,
  Linkedin,
} from '~/components/icons';

const SOCIAL_MEDIA_ICON_MAPPING: Record<string, IconComponent> = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
};

const PLATFORM_URL_REGEXES: Array<[RegExp, string]> = [
  [/www\.facebook\.com/, 'facebook'],
  [/www\.instagram\.com/, 'instagram'],
  [/www\.linkedin\.com/, 'linkedin'],
];

export interface SocialMediaLinkProps {
  title?: string;
  url: string;
}

export const SocialMediaLink = ({
  url,
  title = 'external link to social media',
}: SocialMediaLinkProps) => {
  const platform = useMemo(() => {
    return (
      PLATFORM_URL_REGEXES.find(([regex]) => regex.test(url))?.[1] ?? 'none'
    );
  }, [url]);

  const PlatformIcon = SOCIAL_MEDIA_ICON_MAPPING[platform] ?? GenericSocial;

  return (
    <LinkBox
      width="10"
      height="10"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <LinkOverlay href={url}>
        <PlatformIcon size="2x" title={title} />
      </LinkOverlay>
    </LinkBox>
  );
};
