import { Box, chakra, Container, VStack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { Header as HeaderComponent } from '~/components/header';
import { Footer as FooterComponent } from '~/components/footer';

export interface StandardLayoutProps {
  Header?: JSX.Element;
  Footer?: JSX.Element;
  invertColors?: boolean;
}

export const StandardLayout = ({
  Header = <HeaderComponent />,
  Footer = <FooterComponent />,
  invertColors = false,
  children,
}: PropsWithChildren<StandardLayoutProps>) => (
  <Box sx={invertColors ? { filter: 'invert(1)' } : {}} background="white">
    <Container
      minHeight="100vh"
      maxW={[null, 'xl', '5xl']}
      display="flex"
      flexDir="column">
      <chakra.header mb={4}>{Header}</chakra.header>
      <VStack as="main" spacing={16} flex="1 0 0" alignItems="stretch">
        {children}
      </VStack>
      <chakra.footer mt={4}>{Footer}</chakra.footer>
    </Container>
  </Box>
);
